.popup-2-session-btn {
  margin-top: 80px;
}
.has-search .search-exr {
  background-color: #333333;
  margin-top: 10px;
}
.bo-x {
  background-color: #333333;
}
.cardio-right-row-info-calendarsave {
  width: 100%;
}

.cardio-heading-session {
  display: flex;
}
.cardio-heading-session input {
  background: #1f212d;
  border-radius: 10px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 10px;
  color: rgba(255, 255, 255, 0.5) !important;
  height: 45px;
  width: 40%;
  border: none;
  padding: 5px 15px;
  margin-bottom: 25px;
  margin-left: 15px;
}

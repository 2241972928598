.icon-headernav {
  color: #ff6200;
  font-size: 16px;
}
.button-header {
  cursor: pointer;
}
.notification-dot {
  height: 8px;
  width: 8px;
  background-color: #e10707;
  border-radius: 50%;
  border: 1px solid white;
  display: inline-block;
  position: relative;
  top: -13px;
  left: -3px;
}
.noti-icons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}
.link-down h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 0px;
}
.notification-dot.avtar {
  background: #42d114;
  top: -21px;
  left: -10px;
}
.link-down {
  cursor: pointer;
}
.dropdown-menu.show {
  display: block;
  margin-right: 20px;
  margin-top: 25px;
  background: transparent;
}
.dropdown-item {
  color: #fff;
}
.profile-img-user img {
  width: 74px;
  height: 74px;
  object-fit: cover;
  border: 1px solid #212529;
  border-radius: 40px;
  background: #212529;
}

.boxes {
  background: #252735;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 10%);
  border-radius: 15px;
  padding: 50px;
  margin: 20px;
}

table {
  color: #32b5ff;
  font-style: normal;
  text-align: left;
  background: #252735;
}

table input {
  background: #2e313f;
  border-radius: 10px;
  padding: 5px;
  padding-left: 20px;
  border: 0px;
}
table textarea {
  background: #2e313f;
  border-radius: 10px;
  padding: 5px;
}

.btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.boxes tr td:nth-child(1) {
  width: 30%;
}
button {
  background: #32b5ff;
  border-radius: 50px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  border: 0px;
  padding: 10px;
  padding-left: 60px;
  padding-right: 60px;
  margin-right: 30px;
}
.extra-profile-5-info table tbody tr td input.form-control.units {
  width: 92px;
  text-align: center;
  float: left;
  margin-right: 40px;
}
.extra-profile-5-info .btn button {
  margin-top: 10px;
}
.extra-profile-5-info tr td:nth-child(1) {
  vertical-align: top;
}
.form-control {
  color: white !important;
}
.extra-profile-5-info .form-control::placeholder {
  color: #a7a7a7 !important;
}
.extra-profile-5-info input.form-control {
  margin-bottom: 30px !important;
}

/* .units {
      display: flex;
      gap: 30px;
      padding-left: 10px;
  }
  .units input {
      width: 10%;
  } */

.boxes input.form-control {
  color: white !important;
  background: #2e313f;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 10px;
  border: 0px;
  margin-bottom: 15px;
}
.boxes::placeholder {
  color: #ffffff;
  opacity: 0.6;
}
input[type='date']:not(.has-value):before {
  color: #ffffff;
  opacity: 0.6;
}
.boxes textarea.form-control {
  background: #2e313f;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 10px;
  border: 0px;
}
.units input.form-control {
  width: 15%;
}
.btn a {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #e10707;
  text-decoration: none;
}
.boxes.extra-profile {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.extra-profile td {
  padding-bottom: 10px
}
.btn button {
  margin-right: 0px;
}

.client-nav ul.nav li.nav-item.active a span {
  color: #32b5ff;
}
.client-nav ul.nav li.nav-item.active a {
  opacity: 1;
}

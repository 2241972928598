.chat-form-info {
  padding: 10px 40px 15px 40px;
}
.chat-form-info .chat-box-info {
  display: block;
  position: relative;
  width: 100%;
}

.chat-form-info .messages-info {
  display: inline-block;
  width: 98%;
  padding: 0.5% 15px;
  height: 300px;
  overflow: auto;
  overflow-x: hidden;
}
.chat-form-info .messages-info p {
  margin: 0px;
}
.messages-info .fromThem,
.messages-info .myMessage {
  position: relative;
  max-width: 90%;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #ffffff;
  padding: 15px;
  border-radius: 15px;
  word-wrap: break-word;
  margin-bottom: 25px;
  clear: both;
}
.chat-form-info .messages-info .fromThem {
  background: #2c2c2e;
  max-width: 60%;
  min-width: 90px;
  text-align: start;
  border-radius: 15px;
  float: left;
  clear: both;
  margin-bottom: 25px;
}
.chat-form-info .fromThem:before {
  content: '';
  position: absolute;
  z-index: 2;
  bottom: -2px;
  left: -7px;
  height: 19px;
  border-left: 20px solid #2c2c2e;
  border-bottom-right-radius: 16px 14px;
  transform: translate(0, -2px);
  border-bottom-right-radius: 15px 0px\9;
  transform: translate(-1px, -2px);
}
.chat-form-info .fromThem:after {
  content: '';
  position: absolute;
  z-index: 3;
  bottom: -2px;
  left: 4px;
  width: 26px;
  height: 20px;
  background: #252735;
  border-bottom-right-radius: 10px;
  transform: translate(-30px, -2px);
}
.chat-form-info .myMessage {
  max-width: 60%;
  min-width: 90px;
  background: #32b5ff;
  float: right;
  text-align: start;
}
.chat-form-info .myMessage:before {
  content: '';
  position: absolute;
  z-index: 1;
  bottom: -2px;
  right: -8px;
  height: 19px;
  border-right: 20px solid #32b5ff;
  border-bottom-left-radius: 16px 14px;
  transform: translate(0, -2px);
  border-bottom-left-radius: 15px 0px;
  transform: translate(-1px, -2px);
}
.chat-form-info .myMessage:after {
  content: '';
  position: absolute;
  z-index: 1;
  bottom: -2px;
  right: -42px;
  width: 12px;
  height: 20px;
  background: #252735;
  border-bottom-left-radius: 10px;
  transform: translate(-30px, -2px);
}
.messages-type-box {
  position: relative;
  width: 100%;
  clear: both;
  display: block;
}
.messages-type-box textarea::placeholder {
  color: #a7a7a7;
}
.messages-type-box textarea {
  background: #2e313f;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 40px;
  width: 100%;
  border: none;
  padding: 10px 30px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #a7a7a7;
  margin-top: 5px;
  margin-bottom: 5px;
}
.messages-type-box textarea:focus-visible {
  outline: none;
}
.fromThem-top-icon-img {
  width: 50px;
  margin: 0px;
  padding-left: 20px;
  padding-bottom: 15px;
}
.myMessage-top-icon-img {
  width: 50px;
  float: right;
  margin-top: -70px;
}
.myMessage-top-icon-img img {
  padding-bottom: 15px;
}
.fromthem-reply-message {
  display: block;
  position: relative;
  width: 100%;
  clear: both;
  padding: 0px 10px;
}
.fromthem-reply-message p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #a7a7a7;
}

.messages-info .fromThem-top-icon-img,
.messages-info .myMessage-top-icon-img {
  display: none;
}
.messages-info:first-child .fromThem-top-icon-img,
.messages-info:first-child .myMessage-top-icon-img {
  display: block;
}
.messages-info {
  display: inline-block;
  width: 98%;
  padding: 0.5%;
  height: 50vh !important;
  overflow: auto;
  overflow-x: hidden;
}

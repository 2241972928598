.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  
}
.containers {
  position: relative;
  width: 100%;
}

.containers .forms {
  position: relative;
  display: block;
  width: 100%;
  padding: 30px;
  border-radius: 8px;
  background: rgba(10,14,18, 0.85);
  box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
  max-width: 430px;
  margin: 0px auto;
}

.containers .form {
  width: 100%;
}

.containers.active .login {
  margin-left: -50%;
  opacity: 0;
  transition: margin-left 0.36s ease, opacity 0.3s ease;
}

.containers .signup {
  opacity: 0;
  transition: opacity 0.18s ease;
}

.containers.active .signup {
  opacity: 1;
  transition: opacity 0.4s ease;
}

.containers.active .forms {
  height: 600px;
}

.containers .form .title {
  position: relative;
  font-size: 27px;
  font-weight: 600;
}

.form .title::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 30px;
  background-color: #4070f4;
  border-radius: 25px;
}

.form .input-field {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.input-field input {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  padding: 10px 15px;
  width: 100%;
  outline: none;
  transition: border-bottom-color 0.4s ease;
  background: #fcfdfe;
  border: 1px solid #f0f1f7;
  box-sizing: border-box;
  border-radius: 8px;
}

.login-page .form.login input.form-control {
  color: #999999 !important;
}

.input-field i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
  font-size: 23px;
  transition: color 0.4s ease;
}

.input-field i.icon {
  left: 0;
}

.input-field i.showHidePw {
  right: 0;
  cursor: pointer;
  padding: 12px;
  position: absolute;
  top: 40px;
}

.form .checkbox-text {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.checkbox-text .checkbox-content {
  display: flex;
  align-items: center;
}

.checkbox-content input {
  margin: 0 8px -2px 4px;
  accent-color: #4070f4;
}

.form a.text {
  color: #4070f4;
  text-decoration: none;
}

.form a {
  text-decoration: none;
}

.form a:hover {
  text-decoration: underline;
}

.form .button input {
  font-family: 'Poppins', sans-serif;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  background-color: #32b5ff;
  box-shadow: 0px 4px 12px rgba(55, 81, 255, 0.24);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.6s ease;
  color: #ffffff;
  padding: 14px 15px;
}

.button input:hover {
  background-color: #80ff00;
}

.form .login-signup {
  margin-top: 50px;
  text-align: center;
}
.login-page input::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #000000;
  opacity: 0.4;
}
.login-logo {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 35px;
}
.form .input-field label {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #ffffff;
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  justify-content: space-between;
}
.form .input-field label span {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 13px;
  text-align: right;
  letter-spacing: 0.1px;
  color: #ffffff;
  opacity: 0.7;
}
a.text.signup-link {
  font-weight: 600;
  letter-spacing: 0.2px;
  color: #32b5ff;
  margin-left: 10px;
}
.form .text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.3px;
  color: #c6c6c6;
}

.login-bg {
	background-image: url(/public/assets/login-bg.png);
	background-repeat: repeat;
	background-position: center;
  background-size: cover
}

.main-bg {
	/* background: url(/public/assets/bg.png); */
  background-size: cover;
  background-repeat: repeat;
}



/* Pricing Forth Section css */
.dis-none {
  display: none;
}
.clr-green {
  color: #32b5ff !important;
}
.list-style-none {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.ul-tag-tick-horizotal {
  display: block;
}
.ul-tag-tick-horizotal li {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  display: inline-block;
  margin: 0px 60px;
}
.ul-tag-tick-horizotal li img {
  margin-right: 15px;
}
.hr-line-priceing {
  background-color: #747474;
  border-top: 1px solid #747474;
  max-width: 100px;
  margin: 0px auto;
  margin-top: 26px;
  margin-bottom: 26px;
}
/* switch btn */
.switch-btn-info {
  position: relative;
  width: 100%;
  max-width: 510px;
  margin: 0px auto;
}
.switch-m-y-btn-info {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 15px;
  position: relative;
}
.switch-year-txt,
.switch-month-txt {
  font-size: 14px;
  line-height: 17px;
}
.get-3month-free-info img.img-get3month-icon {
  position: absolute;
  bottom: 14px;
  left: 80px;
}
.pricing-table-info .switch-m-y-btn {
  position: relative;
  display: inline-block;
  width: 57px;
  height: 30px;
  margin-bottom: 0px;
}
.pricing-table-info .switch-m-y-btn input {
  opacity: 0;
  width: 0;
  height: 0;
}
.pricing-table-info .switch-m-y {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #e1e1e1;
  cursor: pointer;
  border-radius: 60px;
  transition: 0.4s;
}
.pricing-table-info .switch-m-y::before {
  content: '';
  position: absolute;
  left: 9px;
  bottom: 7px;
  height: 16px;
  width: 16px;
  background-color: #ffffff;
  border-radius: 50%;
  transition: 0.4s;
}
.pricing-table-info input:checked + .switch-m-y {
  background-color: #e1e1e1;
}
.pricing-table-info input:checked + .switch-m-y::before {
  transform: translatex(25px);
  background-color: #fff;
}
/* end switch btn */
.pricing-section-info .top-content-section h2 {
  font-size: 64px;
  line-height: 78px;
  font-weight: 900;
  text-transform: capitalize;
  margin-bottom: 10px;
}
.pricing-section-info .top-content-section p.p-small {
  font-size: 20px;
  line-height: 27px;
  color: #848199;
}

/* .pricing-table-info .nav-tabs {
    border: none;
    text-align: center;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border-radius: 22px;
    max-width: 267px;
    margin: 0px auto;
    margin-bottom: 70px;
}
.pricing-table-info .nav-tabs li.nav-item {
    width: 50%;
    margin: 0px;
    border: none;
}
.pricing-table-info .nav-tabs .nav-link {
    font-family: "GraphikMedium";
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.833333px;
    color: #848199;
    text-decoration: none;
	border: none;
	padding: 16px 0px;
    border-radius: 22px;
}
.pricing-table-info .nav-tabs .nav-link.active, .pricing-table-info .nav-tabs .nav-item.show .nav-link,
.pricing-table-info .nav-tabs .nav-item .nav-link:hover {
    background: #32B5FF;
    box-shadow: 0px 5px 7px rgb(82 67 194 / 23%);
    border-radius: 22px;
    border: none;
    color: #fff;
}
.save-up-txt-info {
    position: absolute;
    margin-top: -40px;
    margin-left: 270px;
}
.save-price-txt {
	background: linear-gradient(90deg, #33D1C0 0.08%, #76F030 76.12%);
    width: 66px;
    height: 66px;
    display: grid;
    border-radius: 50px;
    align-items: center;
}
.save-price-txt h3 {
    font-size: 13px;
    line-height: 13px;
    text-align: center;
    letter-spacing: 0.833333px;
    color: #FFFFFF;
    margin: 0px;
}
.save-price-txt h3 .save-price-amount {
    font-size: 20px;
    line-height: 18px;
    font-family: "GraphikBold";
	padding-top: 5px;
    display: block;
} */
.pricing-box-title h2 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  text-align: center;
  color: #000000;
  text-transform: capitalize;
  margin-top: 26px;
  margin-bottom: 10px;
}
.pricing-table-content {
  position: relative;
  background: #ffffff;
  border: 1px solid #dbdbdb;
  box-shadow: 0px 4px 5px 1px rgb(193 193 193 / 35%);
  border-radius: 4px;
  padding: 10px;
  height: 100%;
}
/* .pricing-table-box {
    background: #FFFFFF;
    border-radius: 26px;
    padding: 40px;
} */
.pricing-table-content .table-price-amount h3.table-price {
  font-size: 38px;
  line-height: 46px;
  color: #000000;
  font-family: 'GraphikSemibold';
  margin-bottom: 5px;
  text-align: center;
}
.pricing-table-content .table-price-amount .txt-month {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #747474;
}
.pricing-desc-content h3 {
  font-size: 28px;
  line-height: 38px;
  color: #231d4f;
  font-family: 'GraphikSemibold';
  margin-bottom: 24px;
}
ul.ul-tag-pricing-table {
  list-style: none;
  margin: 0px;
  padding: 0px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #747474;
  text-transform: capitalize;
}
ul.ul-tag-pricing-table li {
  margin-bottom: 8px;
}
/* ul.ul-tag-pricing-table {
    font-size: 12px;
    line-height: 18px;
    color: #848199;
    font-family: "GraphikRegular";
    padding-left: 20px;
    list-style: none;
	position: relative;
} */
.pricing-desc-content {
  margin-bottom: 30px;
}
/* ul.ul-tag-pricing-table li {
    margin-bottom: 11px;
	padding-left: 10px;
} */
/* ul.ul-tag-pricing-table li::before {
    content: url(/public/assets/check-circle-1.png);
    display: block;
    position: absolute;
    left: 0px;
} */
.table-pricing-btn a.btn-pricing-start {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #32b5ff;
  text-decoration: none;
  display: block;
  border: 3px solid #32b5ff;
  border-radius: 3px;
  padding: 12px 10px;
  max-width: 220px;
  margin: 0px auto;
}

.most-popular-txt-pricing-table {
  background: #34d1be;
  border-radius: 13.5px;
  padding: 5px 2px;
  max-width: 121px;
  text-align: right;
  width: 100%;
  display: block;
  margin: 0px auto;
  margin-right: 0;
  margin-bottom: 5px;
}
.pricing-table-info .nav-tabs {
  border-bottom: none;
}
.most-popular-txt-pricing-table p {
  margin: 0px;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.833333px;
  color: #ffffff;
}
.pricing-black-box {
  background: #292929;
}
.pricing-black-box h3.table-price,
.pricing-black-box .pricing-desc-content h3,
.pricing-black-box .pricing-box-title h2 {
  color: #fff !important;
}
.pricing-black-box hr.hr-line-priceing {
  background-color: #ddd;
  border-top: 1px solid #ddd;
}
.pricing-black-box .table-price-amount .txt-month,
.pricing-black-box ul.ul-tag-pricing-table {
  color: #ddd !important;
}
.pricing-black-box a.btn-pricing-start {
  background: #32b5ff;
  color: #fff;
}

/* .pricing-black-box ul.ul-tag-pricing-table li::before {
    content: url(/public/assets/check-circle-1-white.png);
} */

/* End Pricing Forth Section css */

table.date-calendar-table-css {
  display: table;
  color: #aaaaaa;
}
table.date-calendar-table-css tr {
  background: #2e313f;
  border-radius: 4px;
}
table.date-calendar-table-css thead tr {
  background: linear-gradient(270deg, #802ad8 0%, #32b5ff 100%);
  opacity: 0.8;
}
table.date-calendar-table-css thead tr th {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  padding: 12px 5px;
  padding-left: 15px;
}
table.date-calendar-table-css tbody tr td {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #aaaaaa;
  padding: 10px 0px;
}

body {
  background-color: #1e1e2b;
}
a {
  text-decoration: none;
}
.App {
  background: #1e1e2b;
}

.App .sidebar {
  background: #252735;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  width: 22%;
  min-height: 100vh;
}
.layout {
  padding: 30px;
  width: 100%;
}
.content {
  margin-top: 30px;
}

.homepage-boxes .box-dataupper h3 {opacity: 0.6;}
.Delpop{
    display: block;
    justify-content: space-around;
    align-items: baseline;
    text-align: center;
}
.Delpop p{
    margin-bottom: 50px;
    margin-right: 25px;
}
.del-btn button {
    padding: 11px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 16px;
}
.Pop-del{
    max-width: 545px;
}
.select-width-100 {
  width: 100% !important;
}
.dis-flex-client {
  display: flex;
  align-items: center;
  column-gap: 20px;
}
.btn-delete {
  cursor: pointer;
}
.client-nav a {
  font-style: normal;
  font-size: 16px;
  line-height: 27px;
  color: #ffffff !important;
  opacity: 0.6;
  display: flex;
  gap: 10px;
  align-items: center;
}
.client-nav a:hover {
  color: #32b5ff;
}
.active {
  color: #32b5ff;
}
.client-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
button {
  background: #32b5ff;
  border-radius: 50px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  border: 0px;
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  margin-right: 30px;
}
.has-search .form-control {
  padding: 10px;
  padding-left: 2.375rem;
  background: #252735;
  border-radius: 10px;
  border: 0px;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  top: 4px;
}
.search-bar {
  width: 38%;
  margin-top: 30px;
  padding-left: 20px;
}
.form-group.has-search {
  position: relative;
}
.boxes {
  background: #252735;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 10%);
  border-radius: 15px;
  padding: 40px;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  margin: 20px;
  position: relative;
  padding-left: 58px;
}
.box-avtar {
}
.homepage-boxes {
  margin: 40px;
}
.box-dataupper h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 20px;
}
.extra-clr h3 {
  color: #32b5ff;
}
.box-datalower {
  display: flex;
  gap: 20px;
}
.box-datalower h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 19px;
  color: #32b5ff;
}
.images-chart {
  display: flex;
  gap: 30px;
  align-items: center;
  cursor: pointer;
}
.extra-clr h3 {
  color: #32b5ff !important;
}
.box-avtar img {
  width: 84px;
  height: 84px;
  object-fit: cover;
  border: 1px solid #212529;
  border-radius: 40px;
  background: #212529;
}
.client-nav ul.nav {
  gap: 20px;
}

body.active-modal {
  overflow-y: hidden;
}
.modal,
.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}
.overlay {
  background: rgba(49, 49, 49, 0.8);
}
.popup-add-client-info {
  background: #252735;
  border-radius: 20px;
  width: 100%;
  max-width: 790px;
  margin: 0px auto;
  position: relative;
  padding: 30px;
}
.popup-add-client-info .close-btn-icon img {
  width: 25px;
  height: 25px;
}
.popup-add-client-content-info {
  position: relative;
}
.popup-add-client-content-info .close-btn-icon {
  position: absolute;
  right: 0px;
  cursor: pointer;
}
.h2-heading h2 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  text-align: center;
}
.empty-space-20 {
  display: block;
  height: 20px;
}
.empty-space-30 {
  display: block;
  height: 30px;
}
form.popup-add-client-form-info textarea.form-control {
  background: #2e313f;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 10px;
  border: none;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #64707a;
  height: 84px;
}
form.popup-add-client-form-info tr td.font-size-22 {
  display: block;
}
form.popup-add-client-form-info textarea.txt-area-clr-white.form-control,
form.popup-add-client-form-info
  textarea.txt-area-clr-white.form-control::placeholder {
  color: #cfcfcf;
}
form.popup-add-client-form-info .form-control {
  margin-bottom: 10px;
}

/* Mui pupup css */
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded[aria-labelledby='customized-dialog-title'] {
  box-shadow: none;
  background-color: transparent;
  max-width: 100% !important;
  width: 100% !important;
  margin: 0px auto;
}
.popup-add-client-info button.MuiButtonBase-root.MuiIconButton-root {
  right: -20px;
  top: -35px;
}
.popup-add-client-info
  button.MuiButtonBase-root.MuiIconButton-root
  svg.MuiSvgIcon-root {
  width: 30px;
  height: 30px;
  fill: #fff;
}
h2#customized-dialog-title {
  padding: 0px !important;
}
.popup-add-client-info.mui-popup-content-div {
  padding: 45px 30px 35px 30px !important;
}

.tooltip-icons-cstm {
  position: relative;
  display: inline-block;
}
.tooltip-icons-cstm .tooltiptext {
  visibility: hidden;
  background-color: #32b5ff;
  color: #fff;
  border-radius: 4px;
  padding: 2px 4px;
  position: absolute;
  left: 0;
  z-index: 1;
  top: 100%;
  margin-top: 5px;
  text-align: center;
  font-size: 12px;
  line-height: normal;
}
.tooltip-icons-cstm:hover .tooltiptext {
  visibility: visible;
}

.stripe-form {
  display: flex;
  flex-direction: column !important;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.stripe-form-card {
  position: relative;
  display: block;
  width: 100%;
  padding: 30px;
  border-radius: 8px;
  background: rgba(10, 14, 18, 0.85);
  box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
  min-width: 400px;
}

@media (min-width: 1800px) {
  .stripe-form {
    flex-direction: row !important;
    min-width: 200px;
  }
}

.extra-settings .boxes tr td:nth-child(1) {
  width: 30%;
}
.extra-settings .boxes input.form-control {
  width: 100%;
}
.extra-settings .boxes textarea.form-control {
  width: 100%;
}
.sett span {
  color: #32b5ff;
}
.btn button {
  margin-right: 0px;
  margin-top: 100px;
}

.btn a {
  text-decoration: underline;
}

/* upload images field css */
.extra-settings .form {
  position: relative;
}
.form-control-upload {
  position: absolute;
  right: 10px;
  max-width: 35%;
  top: 0;
}
.form-control-upload .upload-img-info {
  width: 85px;
  height: 85px;
  object-fit: cover;
  border: 1px solid #212529;
  border-radius: 40px;
  background: #212529;
  margin-left: 80px;
  margin-bottom: 15px;
}
.form-control-upload.img input.form-control {
  width: 100%;
  max-width: 255px;
}
td {
  vertical-align: top;
}
/* end upload images field css */

.reactcalendar-info .react-calendar {
  position: relative;
  background: #161616;
  border-radius: 20px;
  padding: 10px;
}
.reactcalendar-info .react-calendar__navigation {
  display: flex;
  column-gap: 30px;
  align-items: center;
  margin-bottom: 20px;
}
.reactcalendar-info
  .react-calendar__navigation
  .react-calendar__navigation__prev2-button,
.reactcalendar-info
  .react-calendar__navigation
  .react-calendar__navigation__next2-button {
  background: #292929;
  box-shadow: 0px 2px 2px rgb(0 0 0 / 5%);
  width: 25px;
  height: 25px;
  padding: 0px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 19px;
  border-radius: 50px;
  margin: 0px;
}
.reactcalendar-info .react-calendar__navigation__prev-button,
.reactcalendar-info .react-calendar__navigation__next-button {
  color: rgba(255, 255, 255, 0.5);
  background: #292929;
  box-shadow: 0px 2px 2px rgb(0 0 0 / 5%);
  padding: 0;
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 19px;
  border-radius: 50px;
  margin: 0px;
}
.reactcalendar-info .react-calendar__navigation__label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  padding: 0;
  background: transparent;
  margin: 0px;
}

.reactcalendar-info
  .react-calendar__viewContainer
  .react-calendar__month-view__weekdays {
  margin-bottom: 5px;
}
.reactcalendar-info
  .react-calendar__viewContainer
  .react-calendar__month-view__weekdays
  .react-calendar__month-view__weekdays__weekday {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
}
.reactcalendar-info
  .react-calendar__viewContainer
  .react-calendar__month-view__weekdays
  .react-calendar__month-view__weekdays__weekday
  abbr[title] {
  text-decoration: unset;
}
.reactcalendar-info
  .react-calendar__viewContainer
  .react-calendar__month-view__days
  button.react-calendar__tile.react-calendar__month-view__days__day {
  background-color: transparent;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #fff;
  height: 35px;
  padding: 0px;
  margin: 0px;
}
.reactcalendar-info
  .react-calendar__month-view__days
  .react-calendar__tile--now
  abbr[aria-label],
.reactcalendar-info
  .react-calendar__month-view__days
  .react-calendar__tile.react-calendar__tile--active
  abbr[aria-label],
.reactcalendar-info
  .react-calendar__month-view__days
  .react-calendar__tile
  abbr:hover {
  border-radius: 26px;
  height: 35px;
  width: 100%;
  display: flex;
  max-width: 35px;
  margin: 0px auto;
  justify-content: center;
  align-items: center;
}
.reactcalendar-info
  .react-calendar__month-view__days
  .react-calendar__tile.react-calendar__tile--active
  abbr[aria-label],
.reactcalendar-info
  .react-calendar__month-view__days
  .react-calendar__tile
  abbr:hover {
  background: rgba(50, 180, 255, 0.4);
}
.reactcalendar-info
  .react-calendar__month-view__days
  .react-calendar__tile--now
  abbr[aria-label] {
  border: 1px solid #32b5ff;
}
.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
  color: rgba(255, 255, 255, 0.2) !important;
}
.highlights {
  color: #4bb543 !important;
}

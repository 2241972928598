    .box-dataupper {padding-left: 20px;}
    .empty-space-70 {display: block;height: 70px;}
    .margin-top-0{margin-top: 0px;}
    .margin-top-20px{margin-top: 20px!important;}
    .popup-add-team-content-info form.popup-add-client-form-info table tr td:nth-child(2) {width: 50%;}
    .popup-add-team-content-info form.popup-add-client-form-info {max-width: 550px;margin: 0px auto;}


    .mt-80{margin-top: 80px;}
    .mt-0{margin-top: 0px;}
    .pl-20{padding-left: 20px;}
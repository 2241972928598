.calendar-top-bar-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 25px;
  margin-bottom: 25px;
  margin-top: 10px;
  justify-content: space-between;
}
.calendar-left-arrow-img {
  background-color: #121212;
}
.calendar-top-grid {
  display: flex;
  flex-flow: row;
  column-gap: 20px;
}
.calendar-top-bar-info .heading-h2 h2 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 45px;
  color: #ffffff;
  margin-bottom: 0px;
  margin-right: 18px;
}
.calendar-top-bar-info .heading-h2 h2 img {
  margin-top: -5px;
}
.calendar-top-bar-info .left-arrow-btn span,
.calendar-top-bar-info .right-arrow-btn span {
  width: 20px;
  margin-top: 6px;
  display: block;
  cursor: pointer;
}
.calendar-top-bar-info .left-arrow-btn span i.fa,
.calendar-top-bar-info .right-arrow-btn span i.fa {
  font-size: 33px;
}
.calendar-top-bar-info .top-dropdown-btn select.select-tag-icon {
  font-size: 18px !important;
  line-height: 27px !important;
}

.rbc-month-view {
  border: 1px solid #272727;
  height: auto;
}
.rbc-header {
  border-bottom: 1px solid #272727;
}
.rbc-day-bg + .rbc-day-bg,
.rbc-header + .rbc-header {
  border-left: 1px solid #272727;
}
.rbc-month-row + .rbc-month-row {
  border-top: 1px solid #272727;
}
.rbc-header {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #32b5ff;
  padding: 7px 3px;
}
.rbc-off-range-bg,
.rbc-today {
  background: transparent;
}
.rbc-month-row .rbc-row-content .rbc-date-cell button {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #9f9f9f;
}
.rbc-date-cell.rbc-now.rbc-current button {
  background: linear-gradient(270deg, #802ad8 0%, #32b5ff 100%);
  color: #000000;
  padding: 0;
  width: 100%;
  max-width: 24px;
  height: 24px;
}
.rbc-date-cell {
  padding-right: 0px !important;
  height: 125px;
  border: '1px solid red';
  display: flex;
  flex-direction: column;
  text-align: center !important;
}
.rbc-month-row {
  overflow: unset;
}
.rbc-event {
  background-color: transparent;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #9f9f9f;
  text-align: center;
}
button.rbc-button-link.rbc-show-more {
  background-color: transparent;
  width: 100%;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #9f9f9f;
  text-align: center;
}
.rbc-overlay {
  border: none;
  background-color: #252735;
  text-align: center;
}

.calendar-cell-info {
  margin-bottom: 25px;
}
.calendar-cell-info .cardio-h3 h2 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 127.9%;
  text-decoration: underline;
  text-align: center;
  margin-bottom: 15px;
}
.calendar-cell-info h3 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 127.9%;
  text-align: left;
  margin-bottom: 0px;
}
.calendar-cell-info .cell-grid {
  display: grid;
  grid-template-columns: 60% auto;
  column-gap: 5px;
  margin-bottom: 10px;
}
.rbc-calendar {
  background: #1e1e2b;
  height: auto !important;
}

.cln-box-top-links {
  display: flex;
  column-gap: 8px;
  /* position: absolute; */
  top: 10px;
  opacity: 0;
  margin: 7px 10px;
}
.cln-box-top-links div {
  cursor: pointer;
  transition: 0.3 ease-in;
}
.cln-box-top-links div:hover {
  transform: scale(1.8);
}
/* .rbc-addons-dnd-row-body .rbc-row-segment:hover .rbc-event-content .cln-box-top-links,
.on-hover {
  opacity: 1;
} */

.session-box:hover .cln-box-top-links {
  opacity: 1;
}

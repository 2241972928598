.sidebar-componnet li.nav-item {
  margin-top: 5px;
  margin-bottom: 5px;
}
.sidebar-componnet a {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 15px;
  padding-top: 0px;
  padding-bottom: 0px;
  text-decoration: none;
}
.sidebar-componnet .a {
  color: #fff;
}
.sidebar-componnet .nav .active {
  color: #000;
  background: #32b5ff;
  text-decoration: none !important;
}
.sidebar-componnet .nav > li > a {
  padding: 12px 15px;
  margin-top: 5px;
}
.sidebar-componnet .active {
  background: #32b5ff;
  color: #000 !important;
}
.sidebar-componnet .active img {
  filter: invert(200%) sepia(150%) hue-rotate(227deg) brightness(10%)
    contrast(100%);
  -webkit-filter: invert(200%) sepia(150%) hue-rotate(227deg) brightness(10%)
    contrast(100%);
}
